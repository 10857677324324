import { Config } from "config/config.type"
/** @firescoutMockVar Config.config */
const config:Config = {
  "env": "prod",
  "location": "lusini_en_international",
  "shopName": "lusini",
  "locale": "en",
  "rootCategoryId": "EMG",
  "availableCreditCards": [
    "MASTERCARD",
    "VISA"
  ],
  "orderMinimumB2B": 100,
  "links": {
    "contact": "/helpandservice/contact/"
  },
  "i18n": {
    "currency_ISO": "EUR",
    "locale": "en",
    "country": "EN"
  },
  "slugifyLocale": "en",
  "hrefLang": [
    {
      "configFolder": "lusini",
      "locale": "en",
      "hrefLang": "en"
    }
  ],
  "baseUrl": "https://www.lusini.com",
  "modules": {
    "onetrust": {
      "dataDomainScript": "bda0c016-94be-4fd9-943a-b9c09791a327"
    },
    "buzdev": {
      "errorUrl": "https://buz.lusini.com/webhook/com.lusini.commerce-platform/error/1-0-1"
    },
    "cloudinary": {
      "endpoint": "https://res.cloudinary.com/lusini/",
      "transformations": {
        "freisteller_l": "w_1500,h_1500,q_80,c_pad,f_auto",
        "freisteller_l_2": "w_1500,h_1500,q_80,c_pad,f_auto",
        "milieu_l": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "milieu_l_2": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "freisteller_m": "w_500,h_500,q_70,c_pad,f_auto",
        "freisteller_m_2": "w_500,h_500,q_70,c_pad,f_auto",
        "milieu_m": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "milieu_m_2": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "freisteller_s": "w_300,h_300,q_70,c_pad,f_auto",
        "freisteller_s_2": "w_600,h_600,q_70,c_pad,f_auto",
        "milieu_s": "w_300,h_300,g_auto,q_70,c_fill,f_auto",
        "milieu_s_2": "w_600,h_600,g_auto,q_70,c_fill,f_auto",
        "freisteller_xs": "w_50,h_50,q_50,c_pad,fl_lossy,f_auto",
        "freisteller_xs_2": "w_100,h_100,q_50,c_pad,fl_lossy,f_auto",
        "milieu_xs": "w_50,h_50,g_auto,q_50,c_fill,fl_lossy,f_auto",
        "milieu_xs_2": "w_100,h_100,g_auto,q_50,c_fill,fl_lossy,f_auto"
      },
      "fallback_product_picture": "https://res.cloudinary.com/lusini/image/upload/v1643182695/application/products/lusini-online-shop-platzhalter-produktbild-in-kuerze-en.svg"
    },
    "shopware": {
      "salutations": [
        "mr",
        "ms",
        "not_specified"
      ],
      "initialSaluationId": "6d0b33d96b5b400cbabc648c0a4c3f60",
      "accessKey": {
        "b2b": "SWSCGHUDRAJ58EFXLH799B7FML"
      },
      "storefrontUrl": {
        "b2b": "https://www.lusini.com/en/b2b"
      },
      "apiUrl": "https://shop-api.lusini.com/store-api"
    },
    "algolia": {
      "apiKey": "bf4bf9193d5f34cd369647376521dbc8",
      "applicationId": "WAVYBEW6OQ"
    },
    "contentful": {
      "space": "aza65graowyr",
      "environment": "master",
      "accessToken": "OExq-bY8Ujs7M06rQEL1drC2kfafk-qmEzA8DZO0CCE",
      "previewToken": "QP7JxRhc0mYWGrX3I8vj9WdBO_eF7n9r0xnmcX11HAw"
    },
    "dyHybrid": {
      "apiKey": "a72829a6281955d5f8febc70eeb0a8de8a9f4841db410f4012d1fb4bf2dc5ffa",
      "campaignID": "9879544"
    },
    "trustShop": {
      "placeholderImg": "",
      "id": "",
      "url": "https://widgets.trustedshops.com/js/",
      "urlPostfix": ".js"
    },
    "zenloop": {
      "id": ""
    },
    "hotjar": {
      "id": 3549008,
      "version": 6
    }
  },
  "index": {
    "products": "prod_lusini_en_products",
    "productsPriceAsc": "prod_lusini_en_products_priceAsc",
    "categories": "prod_lusini_en_categories",
    "series": "prod_lusini_en_series",
    "productsSuggestion": "prod_lusini_en_products_query_suggestions",
    "productsLatest": "prod_lusini_en_products_noveltiesAsc",
    "productReviews": "prod_lusini_product_reviews"
  },
  "deliveryDate": {
    "shippingTooLong": 200
  },
  "colors": {
    "White": "white",
    "Black": "black",
    "Grey": "grey",
    "Silver": "silver",
    "Brown": "brown",
    "Blue": "blue",
    "Multicoloured": "multicolor",
    "Red": "red",
    "beige": "beige",
    "Green": "green",
    "Transparent": "transparent",
    "Yellow": "yellow",
    "gold": "gold",
    "Orange": "orange",
    "Rosa/Lilac": "lilac"
  },
  "features": {
    "creditCheck": false,
    "devMode": false,
    "trustShop": false,
    "zenloop": false,
    "b2c": false,
    "userLikeWidgetUrl": "",
    "notificationBar": true,
    "customizableUrl": "https://www.lusini.com/en/helpandservice/services/customizing/"
  },
  "abTests": {}
}
export default config